import * as React from "react"
import { Link, graphql } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import Layout from "../components/Layout"

const linkEvent = clipName => {
  typeof window !== "undefined" &&
    typeof window.gtag !== "undefined" &&
    window.gtag("event", "refer_click_product", { clipName })
}

const ProductTemplate = ({ data, location }) => {
  const p = data.allProductsJson.edges[0].node
  return (
    <Layout>
      <main className="max-w-4xl mx-auto flex flex-col justify-around">
        <div className="py-4 justify-left">
          <Link to="/" className="text-blue-500">
            ⬅️ Back to product list
          </Link>
        </div>
        <div
          key={p.name}
          className="my-5 mx-1 sm:flex sm:flex-row-reverse sm:items-center"
        >
          <div className="p-4 ml-3 bg-white rounded-md">
            <h1 className="p-2 text-2xl text-left text-blue-500 font-bold">
              Andersen Window Replacement Clip -{" "}
              <span style={{ whiteSpace: "nowrap" }}>{p.name}</span>
            </h1>
            <p className="px-2 mt-2 text-lg text-gray-700">
              Original Model:{p.model}
              <br />
              Clip Dimensions: {p.dim}
            </p>
            <div className="p-2 sm:py-4">
              <OutboundLink
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                href={p.buyURL}
                onClick={() => {
                  linkEvent(p.name)
                }}
              >
                Order now
              </OutboundLink>
            </div>
          </div>
          <img
            className="w-full max-w-md mx-auto rounded-md"
            src={p.image}
            alt={p.imageAlt}
          />
        </div>
        <div>
          <p>
            The clips shown on this site are reproductions of the plastic grille
            clips (muntin bar clips) used in Andersen Narroline double hung
            Windows. These windows were manufactured in the 70's/80's. The
            original clips have been discontinued by the manufacturer and you
            cannot find them anywhere but here sold as near identical
            reproductions. These clips are designed to be perfect replacement
            for the original part but are made from a durable white nylon
            plastic that is both flexible and strong. Available only in white
            but you can spray paint these to match your window frame color.
          </p>
        </div>
      </main>
    </Layout>
  )
}

export default ProductTemplate

export const query = graphql`
  query ($slug: String!) {
    allProductsJson(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          id
          slug
          name
          model
          imageAlt
          image
          dim
          buyURL
        }
      }
    }
  }
`
